import anime from 'animejs/lib/anime.es'

export default (() => {
    const hero = document.querySelector('.hero-custom');

    if ( hero != null ) {
        const swoosh  = hero.querySelector('svg');
        swoosh.style.opacity = 1;
        anime({
            targets: swoosh.querySelector('path'),
            strokeDashoffset: [anime.setDashoffset, '0'],
            easing: 'easeInOutSine',
            duration: 1500,
            loop: false,
        })

    }
})()