/*eslint-disable no-unused-vars*/
import {
	toggleSiteHeaderBackground,
	modifySearchForMobile,
	showSearchResults
} from '../util/siteHeader'
import '../util/svg'
import '../util/heroCarousel'
import '../util/heroCustom'
import '../util/blockCarousel'
import '../util/specialistCarousel'
import '../util/footerNavigation'
import '../util/headerNavigation'
import { hoverAnimation } from '../util/Card'
import '../util/accordion'

import anime from 'animejs/lib/anime.es'
import renderIcons from '../util/svg'

export default {
	init() {
		// JavaScript to be fired on all pages
		const wrapperClass = '#site-header__search .search-results'
		renderIcons()

		const navButton = document.querySelector('.toggle-navigation');
		const signupButton = document.querySelector('#btn-signup');
		const navWrapper = document.querySelector('.navigation-wrapper');
		const navWrapperActive = 'navigation-wrapper--active';
		const siteHeader = document.querySelector('.site-header');

		if (navButton) {
			navButton.addEventListener('click', function () {
				siteHeader.classList.toggle('backdrop-active');

				if (!navWrapper.classList.contains(navWrapperActive)) {

					if (signupButton) {
						signupButton.classList.add('btn--secondary');
						signupButton.classList.remove('btn--primary');
					}

					navWrapper.classList.add(navWrapperActive);
					animateNavWrapper('100%', true);

				} else {

					if (signupButton) {
						signupButton.classList.remove('btn--secondary');
						signupButton.classList.add('btn--primary');
					}

					navWrapper.classList.remove(navWrapperActive);
					animateNavWrapper();
				}

			})
		}

		document.addEventListener('click', function (event) {

			const searchField = document.querySelector('input[type="search"]')
			if (event.target != searchField) {
				anime({
					targets: wrapperClass,
					opacity: 0,
					translateY: -12,
					duration: 1000,
				})
			}
		})

		//  toggle Search results
		const searchInput = document.querySelector('#site-header__search input[type="search"]')
		if (searchInput) {
			searchInput.addEventListener('input', function (event) {

				// const wrapperEl = document.querySelector(wrapperClass)

				anime({
					targets: wrapperClass,
					opacity: 1,
					translateY: 12,
					duration: 1000,
				})

				showSearchResults(this.value)

				if (this.value.length == 0) {
					anime({
						targets: wrapperClass,
						opacity: 0,
						translateY: -12,
						duration: 1000,
					})
				}


			})
		}

	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired

		// Start hover animation for card components
		const hoverableCards = document.querySelectorAll('.card--hover-animation');
		[].forEach.call(hoverableCards, (card) => {
			card.addEventListener('mouseenter', function () {
				hoverAnimation(this, true)
			});
			card.addEventListener('mouseleave', function () {
				hoverAnimation(this, false)
			});
		})
	},
};


// eslint-disable-next-line
function animateNavWrapper(height = '0', active = false) {

	// animate the backdrop
	anime({
		targets: '.navigation-wrapper',
		height: height,
		duration: 300,
		easing: 'easeInOutSine',
		begin: function () {
			if (active) {
				document.querySelector('.navigation-wrapper').style.display = 'block';
			}
		},
		complete: function () {
			if (!active) {
				document.querySelector('.navigation-wrapper').style.display = 'none';
			}
		},
	})

	// Swoosh animation
	anime({
		targets: '.navigation-wrapper__background svg path',
		strokeDashoffset: [anime.setDashoffset, 0],
		easing: 'easeInOutSine',
		duration: 1500,
		delay: 300,
		loop: false,
	})


}


(function ($) {

    /* Gravity Forms Columns
    =========================================== */
    $(document).on('gform_post_render', function() {
        if ($('.gform_wrapper form').length) {
            $('.gform_wrapper form').each(function() {
                var form = $(this)
                if (form.find('.gf-column').length && form.find('.gf-column').length > 1) {
                    form.find('.gf-column').each(function () {
                        $(this).nextUntil('.gf-column, .gf-column-end, .gform_validation_container').andSelf().wrapAll('<div class="gf-column gfield"></div>');
                        $(this).remove();
                    });

                    if ($('.gf-column-end').length) {
                        $('.gfield.gsection.gf-column-end').remove();
                    } else {
                        $('.gform_footer .btn').appendTo('.gf-column:last-of-type');
                    }
                }
            })
        }
    });

})(jQuery);


