

export default (() => {
    //const widgets  = document.querySelectorAll('.navigation-widget');
    const headings = document.querySelectorAll('.navigation-widget h2');
    const paragraph_headings = document.querySelectorAll('.navigation-widget p');
    // [].forEach.call(widgets, (widget) => {
    //     let icon = 'head';
    //
    //     widget.classList.forEach(cl =>{
    //         if (cl.startsWith('icon-')) {
    //             icon = cl.split('-')[1]
    //         }
    //     })
    //     if(widget.querySelector('h2 span.icon')){
    //     let iconPlaceholder  = widget.querySelector('h2 span.icon')
    //     iconPlaceholder.setAttribute('data-icon', icon)
    //     }else{
    //         let iconPlaceholder  = widget.querySelector('p.headings span.icon')
    //         iconPlaceholder.setAttribute('data-icon', icon)
    //     }
    //
    // });

    [].forEach.call(headings, (heading) => {
        heading.addEventListener('click', toggleNavMenu ,{passive: true})
    });

    [].forEach.call(paragraph_headings, (heading) => {
        heading.addEventListener('click', toggleNavMenu ,{passive: true})
    });


})()

function toggleNavMenu() {
this.parentNode.classList.toggle('navigation-widget--active')
}
