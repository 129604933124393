export default (() => {
        const headings = document.querySelectorAll('.site-footer .widget h3');

        [].forEach.call(headings, (heading) => {
            heading.addEventListener('click', toggleNavMenu ,{passive: true})
        })
        
})()

function toggleNavMenu() {
    this.parentNode.classList.toggle('site-footer__widget--active')
}

