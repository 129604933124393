import anime from 'animejs/lib/anime.es'
import '../util/svg'
import renderIcons from '../util/svg';

// Helper function which checks the presence of the hero-carousel
const hero_carousel_exists = () => {
    const el = document.querySelector('.hero-carousel');
    return (el !== null) ? true : false        
}

// Helper function which checks the presence of the hero-carousel
const hero_visual_exists = () => {
    const el = document.querySelector('.hero');
    return (el !== null) ? true : false        
}

// Add modifier class based on presence of hero-carousel
export const toggleSiteHeaderBackground = (() => {

    const site_header = document.querySelector('.site-header');

    if (site_header){

        if (!hero_carousel_exists() && !hero_visual_exists()) {
            site_header.classList.add('site-header--has-background')
        }

        if (document.body.classList.contains('template-custom-hero')) {
            const hero  = document.querySelector('.hero-custom');    
            site_header.classList.add('site-header--lighten-font')
            window.addEventListener('scroll', function() {
                
                if (window.scrollY < hero.offsetHeight) {
                    site_header.classList.add('site-header--lighten-font')
                } else {
                    site_header.classList.remove('site-header--lighten-font')
                }
            })     
        }
    }
})();

const submitOnEnter = (input) => {
    input.addEventListener('keyup', function(e) {
        if(e.keyCode == 13) {
            input.closest('form').submit()
        }
    })
}

const enableSearchToggle = (el) => {
    el.parentNode.classList.toggle('active');
}

export const SearchBtnActionHandler = (() => {
    const searchBtn = document.querySelector('#site-header__search button');

    if (searchBtn) {
        searchBtn.addEventListener('click', function(e) {
            // Do actions based on resolution
            if (window.matchMedia('(max-width: 1023px').matches) {
                // Mobile; toggle inpput field
                e.preventDefault()
                
                enableSearchToggle(searchBtn)
                submitOnEnter(searchBtn.parentNode)
            } 
        });
    }
})()

export const showSearchResults = (value) => {
    const xhr = new XMLHttpRequest();
    const wrapperClass = '#site-header__search .search-results'
    const wrapperEl = document.querySelector(wrapperClass)
    // eslint-disable-next-line
    xhr.open('GET', `${ajax_props.ajax_url}?action=lore_search&s=${value}&nonce=${ajax_props.nonce}`, true)
    xhr.send()

    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
            let results = JSON.parse(xhr.responseText)

            if (results.length > 0) {
                wrapperEl.innerHTML = '';

                results.forEach(result => {
                    let resEl = document.createElement('a');
                    resEl.innerHTML = result.title
                    resEl.href = result.permalink

                    let svgWrapper = document.createElement('span')
                    svgWrapper.classList.add('icon')
                    svgWrapper.setAttribute('data-icon', 'arrow-right')
                    resEl.appendChild(svgWrapper)
                    wrapperEl.appendChild(resEl)
                })

                renderIcons()

            
            } else {
                anime({
                    targets: wrapperClass,
                    opacity: 0,
                    translateY: -12,
                    easing: 'easeOutElastic',
                    duration: 1000,
                })
            }
        }
    }

}
