import Swiper from 'swiper/bundle'

export default (() => {
    // const bulletDuration = 500

    //eslint-disable-next-line
    var swiper = new Swiper('.specialist-carousel .swiper-container', {
        loop: false,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        slidesPerView: 1,
        spaceBetween: 16,
        breakpoints: {
            768: {
              slidesPerView: 3,
              spaceBetween: 16,
            },
          },
        speed: 1000,
        parallax: true,
        cubeEffect: {
            slideShadows: false,
          },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    })

})();