import Swiper from 'swiper/bundle'
import anime from 'animejs/lib/anime.es'

export default (() => {
    const bulletDuration = 500

    //eslint-disable-next-line
    var swiper = new Swiper('.hero-carousel', {
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        speed: 1000,
        parallax: true,
        cubeEffect: {
            slideShadows: false,
          },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            activeIndexChange: (swiper) => {                
                let svg = document.querySelector(`.swiper-slide[data-swiper-slide-index="${swiper.realIndex}"] svg`);
                
                anime({
                    targets: svg.querySelector('path'),
                    strokeDashoffset: [anime.setDashoffset, 0],
                    easing: 'easeInOutSine',
                    duration: 1500,
                    loop: false,
                })          

            },
            afterInit : (swiper) => {                
                let svg = document.querySelector(`.swiper-slide[data-swiper-slide-index="${swiper.activeIndex -1}"] svg`);
               
                // animate the swoosh
                anime({
                    targets: svg.querySelector('path'),
                    strokeDashoffset: [anime.setDashoffset, 0],
                    easing: 'easeInOutSine',
                    duration: 1500,
                    loop: false,
                })      
                anime({
                    targets: '.swiper-pagination-bullet-active',
                    translateY: '-12px',
                    duration: bulletDuration,
                    easing: 'easeInOutSine',
                })
            },
            slideChange: () => {
                anime({
                    targets: '.swiper-pagination-bullet',
                    translateY: '0px',
                    duration: bulletDuration,
                    easing: 'easeInOutSine',
                })

                anime({
                    targets: '.swiper-pagination-bullet-active',
                    translateY: '-12px',
                    duration: bulletDuration,
                    easing: 'easeInOutSine',
                })
            },
        },
    })

})();