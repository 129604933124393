import anime from 'animejs/lib/anime.es'

export const hoverAnimation = (card, enter = true) => {
    let SVGPath = card.querySelector('span.card__svg svg');
    if (enter) {
        SVGPath.style.opacity = 1;
        anime({
            targets: SVGPath.querySelector('path'),
            strokeDashoffset: [anime.setDashoffset, '0'],
            easing: 'easeInOutSine',
            duration: 900,
            loop: false,
        })
    } else {
        SVGPath.style.opacity = 0;
    }         
}