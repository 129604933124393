
const renderIcons =  () => {
    
    const placeholders = document.querySelectorAll('[data-icon]');
    
    [].forEach.call(placeholders, (placeholder) => {
        
        let iconName = placeholder.dataset.icon
        let iconPath = `/wp-content/themes/lore/resources/assets/images/icons/${iconName}.svg`;
        let svgFile = new XMLHttpRequest();
        svgFile.open('GET', iconPath)
     
        svgFile.addEventListener('load', function(event){
            let xml = event.target.response
            let dom = new DOMParser()
            let svg = dom.parseFromString(xml, 'image/svg+xml')

            placeholder.innerHTML = ''; // reset the inner html
            placeholder.appendChild(svg.rootElement);
            
        })

        svgFile.send(null)

    })

}

export default renderIcons